<template>
    <nav class="navbar" id="navbar">
        <div class="nav-pad">
            <div class="navbar-inner">
                <div class="navbar-logo">
                    <router-link to="/" @click="pageScrollTop">
                        <div class="navbar-logo-img">
                            <img src="./assets/images/logos/mhlogo.webp" alt="melinda hospital logo"/>
                        </div>
                        <div class="navbar-logo-text">
                            <span>Melinda Hospital</span>
                        </div>
                    </router-link>
                </div>
                <div class="navbar-menu" id="navbar-menu">
                    <div class="nm-inner">
                        <div class="nm-part">
                            <router-link to="/" class="nm-part-link" @click="menuToggle">Beranda</router-link>
                        </div>
                        <div class="nm-part">
                            <router-link to="/articles" class="nm-part-link" @click="menuToggle">Artikel</router-link>
                        </div>
                        <div class="nm-part">
                            <router-link to="/dokter" class="nm-part-link" @click="menuToggle(); resetDoctorFilter();" >Cari Dokter</router-link>
                        </div>
                        <div class="nm-part">
                            <router-link to="/layanan" class="nm-part-link" @click="menuToggle(); resetCoeFilter();" >Layanan</router-link>
                        </div>
                        <div class="nm-part">
                            <router-link to="/fasilitas-kesehatan" class="nm-part-link" @click="menuToggle" >Fasilitas Kesehatan</router-link>
                        </div>
                        <div class="nm-part">
                            <router-link to="/about" class="nm-part-link" @click="menuToggle" >About</router-link>
                        </div>
                        <div class="nm-part nm-mobile">
                            <router-link to="/contact-us" class="nm-part-link" @click="menuToggle" >Contact Us</router-link>
                        </div>
                    </div>
                    <div class="nm-mobile-close" @click="menuHide">
                        <img src="./assets/images/icons/mbclose.png" alt="melinda hospital" />
                    </div>
                </div>
                <div class="navbar-menu-bg" id="navbar-menu-bg" @click="menuHide"></div>
                <div class="navbar-button">
                    <div class="nm-desktop">
                        <router-link to="/contact-us" class="nm-part-link" @click="menuToggle" >Contact Us</router-link>
                    </div>
                    <div class="nb-menumob">
                        <img src="./assets/images/icons/mobmenu.png" alt="melinda hospital" @click="menuShow" />
                    </div>
                </div>
            </div>
        </div>
    </nav>
    <router-view/>
    <footer>
        <div class="settings" v-if="window.AndroidInterface">
            <p @click="window.AndroidInterface.openAppSettings()">Settings</p>
        </div>
        <div class="footer-top">
            <div class="staff">
                <div class="staff-icon">
                    <img src="./assets/images/icons/cb-phone.webp" alt="melinda hospital phone" class="staff-icon-phone" />
                    <img src="./assets/images/logos/ftlogo.webp" alt="melinda hospital staff" class="staff-icon-male" />
                </div>
                <div class="staff-text">
                    <p>Jangan sungkan untuk menghubungi staf resepsionis kami yang ramah jika Anda memiliki pertanyaan medis</p>
                </div>
            </div>
            <div class="sosign">
                <div class="socials">
                    <!-- <a href="https://www.facebook.com">
                    <div class="socials-icon">
                        <img src="./assets/images/icons/cc-facebook.png" alt="melinda hospital socials">
                    </div>
                    </a> -->
                    <!-- <a href="https://www.twitter.com/melindahospital" rel="nofollow" target="_blank">
                    <div class="socials-icon">
                        <img src="./assets/images/icons/xicon.webp" alt="melinda hospital socials">
                    </div>
                    </a> -->
                    <!-- <a href="https://www.linkedin.com">
                    <div class="socials-icon">
                        <img src="./assets/images/icons/cc-linkedin.png" alt="melinda hospital socials">
                    </div>
                    </a>
                    <a href="https://www.instagram.com">
                    <div class="socials-icon">
                        <img src="./assets/images/icons/cc-instagram.png" alt="melinda hospital socials">
                    </div>
                    </a> -->
                </div>
                <div class="signup">
					<div class="signup-button">
						<button @click="showModal('signup-modal');">Pendaftaran</button>
					</div>
					<div class="modal signup-modal" id="signup-modal">
						<div class="signup-modal-head">
							<div class="smh-close" @click="hideModal('signup-modal');">
								<span>x</span>
							</div>
						</div>
						<div class="signup-modal-body">
							<div class="smb-inner">
								<div class="smb-form">
									<div class="smb-form-input poli-elput" v-if="polyRegistration?.facility?.data">
										<input type="hidden" name="facility" label="Poli" class="poli-input" id="sfi-input-facility"/>
										<div class="sfi-label">Rumah Sakit</div>
										<div class="sfi-select">
											<div class="sfi-select-button" id="ssb-signup-facility" @click="optionToggle('ssb-signup-facility');">
												<div class="btn-cover float-button"></div>
												<div class="ssb-text" id="st-signup-facility" v-if="polyRegistration?.facility?.selected">
													<span>{{ polyRegistration.facility.selected.name }}</span>
												</div>
												<div class="ssb-text" id="st-signup-facility" v-else>
													<span>Pilih Rumah Sakit</span>
												</div>
												<div class="float-icon">
													<img src="./assets/images/icons/arrow-1.png" alt="rsia melinda"/>
												</div>
											</div>
											<div class="sfi-select-option float">
												<div class="sso-notif" v-if="polyRegistration?.facility?.data?.length > 0">
													<span>{{ 'Ada ' + polyRegistration?.facility?.data?.length + ' Rumah Sakit' }}</span>
												</div>
												<div class="sso-button-list">
													<div class="sso-button" v-for="dt in polyRegistration.facility.data" :key="dt" @click="selectOption(dt, {button: 'ssb-signup-facility', input: 'sfi-input-facility', name: 'st-signup-facility'}); usePolyclinicStore().selectFacility(dt); optionToggle('ssb-signup-facility');">
														<span>{{ dt.name }}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="smb-form-input poli-elput" v-if="polyRegistration?.polyclinic?.data">
										<input type="hidden" name="specialist" label="Poli" class="poli-input" id="sfi-input-specialist"/>
										<div class="sfi-label">Pilih Spesialis</div>
										<div class="sfi-select">
											<div class="sfi-select-button" id="ssb-signup-specialist" @click="optionToggle('ssb-signup-specialist');">
												<div class="btn-cover float-button"></div>
												<div class="ssb-text" id="st-signup-specialist" v-if="polyRegistration?.polyclinic?.selected">
													<span>{{ polyRegistration?.polyclinic?.selected?.name }}</span>
												</div>
												<div class="ssb-text" id="st-signup-specialist" v-else>
													<span>Pilih Spesialis</span>
												</div>
												<div class="float-icon">
													<img src="./assets/images/icons/arrow-1.png" alt="rsia melinda"/>
												</div>
											</div>
											<div class="sfi-select-option float">
												<div class="sso-notif" v-if="polyRegistration?.polyclinic?.data?.length > 0">
													<span>{{ 'ada ' + polyRegistration?.polyclinic?.data?.length + ' poli' }}</span>
												</div>
												<div class="sso-button-list">
													<div class="sso-button" v-for="sp in polyRegistration?.polyclinic?.data" :key="sp" @click="selectOption(sp, {button: 'ssb-signup-specialist', input: 'sfi-input-specialist', name: false}); usePolyclinicStore().selectPoly(sp); optionToggle('ssb-signup-specialist');">
														<span>{{ sp.name }}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="smb-form-input poli-elput" v-if="polyRegistration?.doctor?.data">
										<input type="hidden" name="doctor" label="Dokter" class="poli-input" id="sfi-input-doctor"/>
										<div class="sfi-label">Pilih Dokter</div>
										<div class="sfi-select">
											<div class="sfi-select-button" id="ssb-signup-doctor" @click="optionToggle('ssb-signup-doctor');">
												<div class="btn-cover float-button"></div>
												<div class="ssb-text" id="st-signup-doctor" v-if="polyRegistration?.doctor?.selected">
													<span>{{ polyRegistration?.doctor?.selected?.user?.name }}</span>
												</div>
												<div class="ssb-text" id="st-signup-doctor" v-else>
													<span>Pilih Dokter</span>
												</div>
												<div class="float-icon">
													<img src="./assets/images/icons/arrow-1.png" alt="rsia melinda"/>
												</div>
											</div>
											<div class="sfi-select-option float">
												<div class="sso-notif" v-if="polyRegistration?.doctor?.data.length > 0">
													<span>{{ 'ada ' + polyRegistration?.doctor?.data.length + ' dokter' }}</span>
												</div>
												<div class="sso-button-list">
													<div class="sso-button" v-for="doctor in polyRegistration.doctor.data" :key="doctor" @click="selectOption(doctor.user, {button: 'ssb-signup-doctor', input: 'sfi-input-doctor', name: 'st-signup-doctor'}); usePolyclinicStore().selectDoctor(doctor); optionToggle('ssb-signup-doctor');">
														<span>{{ doctor.user.name }}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="smb-form-input poli-elput" v-if="polyRegistration?.schedule?.data">
										<input type="hidden" name="schedule" label="Jam Praktek" class="poli-input" id="sfi-input-schedule"/>
										<div class="sfi-label">Pilih Jam Praktek</div>
										<div class="sfi-select">
											<div class="sfi-select-button" id="ssb-signup-schedule" @click="optionToggle('ssb-signup-schedule');">
												<div class="btn-cover float-button"></div>
												<div class="ssb-text" id="st-signup-schedule">
													<span>Pilih Jam Praktek</span>
												</div>
												<div class="float-icon">
													<img src="./assets/images/icons/arrow-1.png" alt="rsia melinda"/>
												</div>
											</div>
											<div class="sfi-select-option float" v-if="polyRegistration?.schedule?.data?.length">
												<div class="sso-notif" v-if="polyRegistration?.schedule?.data?.length > 0">
													<span>{{ 'ada ' + polyRegistration?.schedule?.data?.length + ' jadwal, klik pada tombol waktu untuk memilih' }}</span>
												</div>
												<div class="sso-button-list">
													<div class="sso-button sso-button-daytime" v-for="sc in polyRegistration.schedule.data" :key="sc">
														<div class="sso-button-schedule">
															<div class="sbs-day">
																<span>{{ sc.name }}</span>
															</div>
															<div class="sbs-clock">
																<div class="sbs-clock-list" v-for="time in sc.schedules" :key="time">
																	<div class="scl-inner" @click="selectOption({id: sc.id, name: sc.name + ', ' + time.starts + ' - ' + time.ends}, {button: 'ssb-signup-schedule', input: 'sfi-input-schedule', name: 'st-signup-schedule'}); usePolyclinicStore().selectSchedule({day: sc.name, time: {id: time.id, starts: time.starts, ends: time.ends}}); optionToggle('ssb-signup-schedule'); inputFocus('poli-input-name');" v-if="time.starts && time.ends">
																		<span>{{ time.starts }} - {{ time.ends }}</span>
																	</div>
																	<div class="scl-inner scl-inner-appointment" @click="selectOption({id: sc.id, name: sc.name + ', By Appointment'}, {button: 'ssb-signup-schedule', input: 'sfi-input-schedule', name: 'st-signup-schedule'}); usePolyclinicStore().selectSchedule({day: sc.name, time: {id: time.id, starts: null, ends: null}}); optionToggle('ssb-signup-schedule'); inputFocus('poli-input-name');" v-else>
																		<span>By Appointment</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
                                            <div class="sfi-select-option float" v-else>
                                                <p class="sso-note">Tidak ada jadwal</p>
                                            </div>
										</div>
									</div>
									<div class="smb-form-input poli-elput">
										<div class="sfi-label">Nama Pasien</div>
										<input type="text" name="name" class="poli-input" id="poli-input-name" label="Nama Pasien" placeholder="masukkan nama pasien" autocomplete="off" next="poli-input-phone" action="focus" @input="usePolyclinicStore().patientsName('poli-input-name');" @keypress="nextClick"/>
										<div class="sfi-alert"></div>
									</div>
									<div class="smb-form-input poli-elput">
										<div class="sfi-label">Nomor Ponsel</div>
										<input type="text" name="phone" class="poli-input" id="poli-input-phone" label="Nomor Ponsel" placeholder="nomor ponsel" autocomplete="off" next="poli-button-submit" action="click" @input="usePolyclinicStore().phoneEntry('poli-input-phone');" @keypress="nextClick"/>
										<div class="sfi-alert"></div>
									</div>
									<div class="smb-form-input">
										<button id="poli-button-submit" @click="usePolyclinicStore().submitPolyRegistration();" v-if="!smLoading">Daftar Sekarang</button>
										<button id="poli-button-submit" v-else>Loading...</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
        </div>
        <div class="footer-middle">
            <!-- <div class="fm-logo">
                <img src="./assets/favicon.png" alt="melinda hospital logo"/>
            </div> -->
            <div class="fm-text">
                <div class="fm-office">
                    <h2>RSIA Melinda</h2>
                    <p>Jl. Pajajaran no 46, Bandung. Jawa Barat - Indonesia 40171</p>
                    <p>IGD : (022) 4212177</p>
                    <p>Hunting : (022) 4222788</p>
                    <p>Pendaftaran : 0852 2338 8888 (Call & WhatsApp)</p>
                </div>
                <div class="fm-office">
                    <h2>RS Melinda 2</h2>
                    <p>Jl. Dr. Cipto no 1, Bandung. Jawa Barat - Indonesia</p>
                    <p>UGD: (022) 4232777</p>
                    <p>Hunting: (022) 4233777</p>
                    <p>Pendaftaran: 08157100888</p>
                </div>
                <div class="fm-office">
                    <h2>Melinda Cardio Vascular Center</h2>
                    <p>Jl. Dr. Cipto no 11, Bandung. Jawa Barat - Indonesia</p>
                    <p>IGD : (022) 4226 5911</p>
                    <p>Hunting : (022) 6319 9888</p>
                    <p>Pendaftaran : 0813 1284 5500 (Call & WhatsApp)</p>
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="fb-left">
                <p>© {{ new Date().getFullYear() }} Melinda Hospital, All Rights Reserved.</p>
            </div>
            <div class="fb-right">
                <div class="fbr-medals">
                    <img src="./assets/images/icons/medals.webp" alt="melinda hospital medals">
                </div>
                <div class="fbr-text">
                    <router-link to="/">Privacy Policy</router-link>
                    <router-link to="/" class="cookies-link">Cookies</router-link>
                </div>
            </div>
        </div>
        <div class="notification" id="notification">
            <div class="notification-head">
                <h5 class="notification-message" id="notification-title"></h5>
            </div>
            <div class="notification-body">
                <p class="notification-message" id="notification-message"></p>
            </div>
        </div>
        <div class="notification-confirmation" id="notification-confirmation">
            <div class="notification-head">
                <h5 class="notification-message" id="notification-confirmation-title"></h5>
            </div>
            <div class="notification-body">
                <div class="notification-message" id="notification-confirmation-message" v-if="notificationConfirmationData">
                    <div class="notification-message-text" v-if="notificationConfirmationData.status">
                        <div class="nmt-true" v-if="notificationConfirmationData.scanned">
                            <p>Peserta ini sudah pernah dipindai pada:</p>
                            <p style="color: blue;">{{ notificationConfirmationData.scanned_at }}</p>
                        </div>
                        <div class="nmt-false" v-else>
                            <p>Peserta ini Valid</p>
                        </div>
                    </div>
                    <div class="notification-message-text" v-else>
                        <div class="nmt-error">
                            <p>{{ notificationConfirmationData.message }}</p>
                        </div>
                    </div>
                    <template v-if="notificationConfirmationData.rows?.length">
                        <div class="notification-message-row" v-for="nt in notificationConfirmationData.rows" :key="nt">
                            <div class="nmr-param">
                                <span>{{ nt.param }}</span>
                            </div>
                            <div class="nmr-value">
                                <span>{{ nt.value }}</span>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div class="notification-button">
                <div class="nb-ok">
                    <span @click="hideNotificationConfirmation">Tutup</span>
                </div>
            </div>
        </div>
        <div class="link-tree-icon" id="link-tree-icon">
            <a href="https://www.linktr.ee/melindahealthcare" target="_blank">
                <img src="./assets/images/logos/mhlogo.webp" alt="melinda hospital logo"/>
            </a>
        </div>
        <div class="go-top" @click="pageScrollTop">
            <img src="./assets/images/icons/arrow-1.png" alt="melinda hospital" />
        </div>
    </footer>
</template>
<script setup>
    import { computed } from "@vue/runtime-core";
    import { useGeneralStore } from "./stores/general";
    import { usePolyclinicStore } from "./stores/polyclinic";
    // import { onMounted } from 'vue';
    // import { useTransactionStore } from "./stores/transaction";
    import { 
        // getCookie, 
        showModal, hideModal, optionToggle, selectOption, inputFocus, nextClick 
    } from './functions/general';
    import { resetFilter as resetDoctorFilter } from './functions/doctor';
    import { resetFilter as resetCoeFilter } from './functions/coe';
    import { useNotificationStore } from "./stores/notification";
    import { hideNotificationConfirmation } from '@/functions/notification';
    const window = computed(() => useGeneralStore().window);
    const polyRegistration = computed(() => usePolyclinicStore().polyRegistration);
    const smLoading = computed(() => usePolyclinicStore().smLoading);
    const notificationConfirmationData = computed(() => useNotificationStore().notificationConfirmationData);
    // onMounted(() => {
    //     if(getCookie('cart')){
    //         useTransactionStore().setCart(JSON.parse(getCookie('cart')));
    //     }
    // });
    usePolyclinicStore().setPolyRegistration();
    function menuToggle(){
        const nm = document.getElementById('navbar-menu');
        const nb = document.getElementById('navbar-menu-bg');
        if(screen.width <= 750){
            if(nm.style.left == '26%'){
                nm.style.left = '110%';
                nb.style.width = '0';
            }else{
                nm.style.left = '26%';
                nb.style.width = '100%';
            }
        }
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    function menuShow(){
        document.getElementById('navbar-menu').style.left = '26%';
        document.getElementById('navbar-menu-bg').style.width = '100%';
    }
    function menuHide(){
        document.getElementById('navbar-menu').style.left = '110%';
        document.getElementById('navbar-menu-bg').style.width = '0';
    }
    function pageScrollTop(){
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
</script>
<style>
/* @import url('./assets/css/ql-editor.css'); */
    @font-face {
        font-family: 'roboto';
        src: url('assets/fonts/roboto/Roboto-Regular.ttf') format('truetype');
        font-display: swap;
    }
    @font-face {
        font-family: 'poppins';
        src: url('assets/fonts/poppins/Poppins-SemiBold.ttf') format('truetype');
        font-display: swap;
        font-weight: bold;
    }
    @font-face {
		font-family: 'poppins-regular';
		src: url('assets/fonts/poppins/Poppins-Regular.ttf') format('truetype');
		font-display: swap;
		/* font-weight: bold; */
	}
    @font-face {
		font-family: 'poppins-bold';
		src: url('assets/fonts/poppins/Poppins-Bold.ttf') format('truetype');
		font-display: swap;
		/* font-weight: bold; */
	}
	@font-face {
		font-family: 'poppins-medium';
		src: url('assets/fonts/poppins/Poppins-Medium.ttf') format('truetype');
		font-display: swap;
		/* font-weight: bold; */
	}
    @font-face {
        font-family: 'poppins-light';
        src: url('assets/fonts/poppins/Poppins-Light.ttf') format('truetype');
        font-display: swap;
        /* font-weight: bold; */
    }
    @font-face {
        font-family: 'varela-round';
        src: url('assets/fonts/varela-round/VarelaRound-Regular.ttf') format('truetype');
        font-display: swap;
        /* font-weight: bold; */
    }
    @font-face {
        font-family: 'trueno';
        src: url('assets/fonts/trueno/TruenoRg.otf') format('opentype');
        font-display: swap;
        /* font-weight: bold; */
    }
    :root {
        --black: #000000;
        --primary: #75C6EF;
        --primary-hover: #63bae6;
        --white: #ffffff;
        --grey: #575757;
        --dark-grey: #444444;
        --red: red;
        --softgrey: lightgrey;
        --smoke: whitesmoke;
        --navy: #1B4674;
        --navy-hover: #274c74;
        --cream: #FCE8CE;
        --cream-bg: #FEF8F0;
        --orange: #ee992a;
        --orange-hover: #cc811f;
        --green: #08bb26;
    }
    *{
        box-sizing: border-box;
    }
    html{
        scroll-behavior: smooth;
        display: flex;
        justify-content: center;
        font-size: 16px;
    }
    body{
        width: 100%;
        margin: 0;
        font-family: varela-round;
        font-size: 1rem;
        min-width: 250px;
        max-width: 2500px; /* same as .nav-pad */
    }
    ::-webkit-scrollbar{
        width: 0.5rem;
    }
    ::-webkit-scrollbar-thumb{
        background: var(--navy);
    }
    :any-link{
		color: var(--navy);
		text-decoration: none;
	}
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    input:-webkit-autofill{
        -webkit-text-fill-color: var(--grey) !important;
    }
    .color-green{
        color: green;
    }
    .color-red{
        color: red;
    }
    .p-note{
        font-family: Arial, Helvetica, sans-serif;
        font-style: italic;
        color: var(--softgrey);
        text-align: center;
    }
    .waiting-process{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        animation: pulse-bg-transparent 1s infinite;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--grey);
        z-index: 15;
    }
    .button-main a{
        background: var(--navy);
        color: var(--white);
        padding: 0.75rem;
        padding-left: 2rem;
        padding-right: 2rem;
        border-radius: 2rem;
        text-decoration: none;
    }
    .content{
        margin: auto;
        padding-top: 4.81rem;
        min-height: 500px;
        font-family: poppins;
    }
    @media print {
        .no-print{
            display: none;
        }
    }
    @keyframes pulse-bg{
        0%{
            background-color: #eee;
        }
        50%{
            background-color: #e0e0e0;
        }
        100%{
            background-color: #eee;
        }
    }
    @keyframes pulse-bg-transparent{
        0%{
            background-color: rgba(238, 238, 238, 0.658);
        }
        50%{
            background-color: #e0e0e065;
        }
        100%{
            background-color: rgba(238, 238, 238, 0.548);
        }
    }
    @keyframes pulse-bg-white{
        0%{
            background-color: #eee;
        }
        50%{
            background-color: #ffffff;
        }
        100%{
            background-color: #eee;
        }
    }
    @keyframes pulse-bg-navy{
        0%{
            background-color: #a1c1e2;
        }
        50%{
            background-color: #1B4674;
        }
        100%{
            background-color: #a1c1e2;
        }
    }
    @keyframes pulse-bg-bluesky{
        0%{
            background-color: #cceeff;
        }
        50%{
            background-color: #75C6EF;
        }
        100%{
            background-color: #cceeff;
        }
    }
    @-webkit-keyframes animatenlloption {
        from {top:200%; opacity:0}
        to {top:75%; opacity:1}
    }
    @keyframes animatenlloption {
        from {top:200%; opacity:0}
        to {top:75%; opacity:1}
    }
    @-webkit-keyframes animatenotification {
        from {right:-100%; opacity:0}
        to {right:10%; opacity:1}
    }
    @keyframes animatenotification {
        from {right:-100%; opacity:0}
        to {right:10%; opacity:1}
    }
    @-webkit-keyframes animateCategory {
        from {top:200%; opacity:0}
        to {top:110%; opacity:1}
    }
    @keyframes animateCategory {
        from {top:200%; opacity:0}
        to {top:110%; opacity:1}
    }
    @-webkit-keyframes animatesignup {
		from {top:-100%; opacity:0}
		to {top:0; opacity:1}
	}
	@keyframes animatesignup {
		from {top:-100%; opacity:0}
		to {top:0; opacity:1}
	}
    .modal{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #00000070;
        z-index: 20;
        display: none;
        padding-top: 5%;
        text-align: center;
    }
    .modal-active{
        display: block;
    }
    .modal-close{
        position: absolute;
        top: 0;
        right: 0;
        color: var(--grey);
        background: var(--white);
        padding: 1rem;
        z-index: 15;
        cursor: pointer;
        transition: 0.2s;
    }
    .modal-close:hover{
        color: var(--primary-hover);
    }
    .modal-content{
        position: relative;
        width: 75%;
        height: 90%;
        background: var(--white);
        padding: 1rem;
        margin-left: 12.5%;
        border-radius: 5px;
        -webkit-animation-name: animatetop;
        -webkit-animation-duration: 0.4s;
        animation-name: animatetop;
        animation-duration: 0.4s;
        transition: 0.2s;
        overflow: auto;
    }
    .modal-content::-webkit-scrollbar-thumb{
        border-radius: 10px;
    }
    @media only screen and (max-width: 1500px){
        .modal-content{
            width: 90%;
            margin-left: 5%;
        }
    }
    @media only screen and (max-width: 750px){
        .button-main a{
            font-size: 0.75rem;
            padding: 0.5rem 1rem 0.5rem 1rem;
        }
        .crumb-link{
            color: var(--navy);
        }
        .modal{
            padding-top: 0;
        }
        .modal-content{
            width: 100%;
            height: 100%;
            margin-left: 0;
            border-radius: 0;
            padding-left: 0;
            padding-right: 0;
        }
        .modal-content::-webkit-scrollbar-thumb{
            border-radius: 0;
        }
    }
    /* navbar */
    .navbar{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 4.81rem;
        display: flex;
        border: 1px solid whitesmoke;
        font-family: roboto;
        font-weight: 600;
        background: var(--white);
        z-index: 16;
        white-space: nowrap;
        user-select: none;
        transition: 0.2s;
        justify-content: center;
        /* background-image: url('./assets/images/backgrounds/sbluemgmd.jpg'); */
        background-repeat: no-repeat;
        background-position: top left;
        background-size: cover;
    }
    .nav-pad{
        width: 100%;
        max-width: 2500px; /* same as body */
        padding-top: 0.5rem;
        padding-left: 10%;
        padding-right: 10%;
        transition: 0.2s;
    }
    .navbar-inner{
        display: flex;
        flex-wrap: nowrap;
        text-align: center;
        height: 100%;
    }
    .navbar-logo{
        width: 22%;
        text-align: left;
        transition: 0.2s;
    }
    .navbar-logo a{
        text-decoration: none;
        color: #79b7f1;
    }
    .navbar-logo-img{
        position: relative;
        width: 100%;
        height: 100%;
        /* border: 1px solid green; */
    }
    .navbar-logo-img img{
        width: 100%;
        max-width: 300px;
        max-height: 70px;
    }
    .navbar-logo-text{
        font-style: italic;
        display: none;
    }
    .navbar-menu{
        width: 63%;
        padding-top: 0.7rem;
        transition: 0.2s;
        overflow: hidden;
        z-index: 15;
    }
    .navbar-menu-bg{
        position: fixed;
        top: 0;
        left: 110%;
        width: 0;
        height: 150%;
        background: #000000;
        opacity: 0.5;
        z-index: 10;
    }
    /* .navbar-login-form{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 150%;
        background: #58585844;
        z-index: 15;
        display: none;
        padding-top: 5rem;
        font-family: poppins;
    }
    .nlf-inner{
        position: relative;
        width: 37%;
        min-height: 370px;
        background: var(--white);
        margin: auto;
        border-radius: 10px;
        -webkit-animation-name: animatetop;
        -webkit-animation-duration: 0.4s;
        animation-name: animatetop;
        animation-duration: 0.4s;
        transition: 0.2s;
    } */
    @-webkit-keyframes animatetop {
        from {top:-300px; opacity:0} 
        to {top:0; opacity:1}
    }
    @keyframes animatetop {
        from {top:-300px; opacity:0}
        to {top:0; opacity:1}
    }
    /* .ni-head{
        position: relative;
        height: 2rem;
        z-index: 5;
    }
    .nlf-content{
        display: flex;
        width: 100%;
        overflow: hidden;
        text-align: left;
        scroll-behavior: smooth;
        z-index: 1;
    }
    .nlf-inner h2{
        text-align: center;
        margin: 0;
        margin-bottom: 1rem;
    }
    .nlf-part{
        position: relative;
        min-width: 100%;
        width: 100%;
        padding: 1%;
        padding-left: 5%;
        padding-right: 5%;
    }
    .nlf-close{
        position: absolute;
        top: 0;
        right: 0;
        padding: 1rem;
        cursor: pointer;
        transition: 0.2s;
    }
    .nlf-close:hover{
        color: var(--primary-hover);
    }
    .nlf-input{
        position: relative;
        height: 2rem;
        border-bottom: 1px solid lightgrey;
        margin-bottom: 1.5rem;
        text-align: left;
    }
    .nlf-input input{
        height: 100%;
        width: 90%;
        border: none;
        outline: none;
        font-size: 1rem;
        color: #575757;
    }
    .nlf-label{
        position: absolute;
        top: 0;
        left: 0;
        background: var(--white);
        color: #575757;
        font-size: 0.9rem;
        padding: 1%;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        height: 100%;
        width: 100%;
        transition: 0.2s;
        text-align: left;
        border-radius: 5px;
        cursor: text;
    }
    .nlf-button{
        text-align: center;
        font-weight: 300;
        margin-bottom: 2rem;
    }
    .nlf-button-text{
        padding: 1%;
        padding-left: 5%;
        padding-right: 5%;
        background: var(--primary);
        color: var(--white);
        cursor: pointer;
    }
    .nlf-button-text:hover{
        background: var(--primary-hover);
    }
    .nlf-link{
        color: var(--primary);
        text-decoration: none;
    } */
    .nm-inner{
        display: flex;
        flex-wrap: nowrap;
        background: var(--white);
    }
    .nm-part{
        padding: 0.5rem;
        width: auto;
        margin: auto;
    }
    .nm-part-link{
        text-decoration: none;
        color: #575757;
        transition: 0.2s;
    }
    .nm-part-link:hover{
        color: var(--navy-hover);
    }
    .router-link-active{
        color: var(--navy);
    }
    /* .cart-num{
        color: red;
        font-size: 0.75rem;
    }
    .cart-product{
        color: green;
        font-size: 0.75rem;
    } */
    .nm-mobile{
        display: none;
    }
    .nm-mobile span{
        background: #FCE8CE;
        color: #575757;
        padding: 0.5rem;
        padding-left: 2rem;
        padding-right: 2rem;
        border-radius: 5px;
        transition: 0.2s;
    }
    .nm-mobile-close{
        display: none;
    }
    /* .nm-mobile-loggedin{
        display: none;
    } */
    .navbar-button{
        width: 15%;
        text-align: right;
        padding: 1.2rem;
        padding-right: 0;
        transition: 0.2s;
    }
    .navbar-button span{
        background: #FCE8CE;
        color: #575757;
        padding: 0.75rem;
        padding-left: 2rem;
        padding-right: 2rem;
        border-radius: 2rem;
        transition: 0.2s;
    }
    .navbar-button span:hover{
        background: #f7ce9a;
    }
    /* .nb-login-loggedin{
        position: relative;
        min-height: 50px;
        cursor: pointer;
    } */
    /* .nll-button{
        background: unset;
        color: #575757;
        border-radius: 0;
        transition: 0.2s;
        font-size: 0.9rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .nll-button:hover{
        background: var(--white);
    } */
    /* .nll-select{
        position: absolute;
        top: 75%;
        right: 0;
        width: 100%;
        background: var(--white);
        color: var(--grey);
        border-radius: 5px;
        padding: 0.5rem;
        text-align: left;
        display: none;
        box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15), 0 5px 20px 0 rgba(0, 0, 0, 0.15);
        animation-name: animatenlloption;
        animation-duration: 0.2s;
        transition: 0.2s;
        overflow: hidden;
    }
    .nll-option{
        cursor: pointer;
        padding: 0.5rem;
        transition: 0.2s;
    }
    .nll-option:hover{
        background: var(--smoke);
    } */
    .nb-menumob{
        display: none;
        z-index: 100;
    }
    .nb-menumob img{
        max-width: 2rem;
    }
    /* .stb-active{
        position: fixed;
        top: 5.5rem;
        right: 10%;
        width: 24%;
    }
    .stb-absolute{
        position: absolute;
        right: 0;
        bottom: 1rem;
        width: 100%;
    } */
    @media only screen and (min-width: 2501px){
        .nav-pad{
            padding-left: 16rem;
            padding-right: 16rem;
        }
    }
    @media only screen and (max-width: 1500px){
        @-webkit-keyframes animatenotification {
            from {right:-100%; opacity:0}
            to {right:5%; opacity:1}
        }
        @keyframes animatenotification {
            from {right:-100%; opacity:0}
            to {right:5%; opacity:1}
        }
        .nav-pad{
            padding-left: 5%;
            padding-right: 5%;
        }
        .navbar-logo{
            width: 12%;
            padding: 0.75rem 0 0 0;
        }
        /* .navbar-logo img{
            height: 75%;
        } */
        .navbar-menu{
            width: 79%;
        }
        .navbar-button{
            width: 12%;
        }
        /* .stb-active{
            right: 5%;
            width: 27%;
        } */
    }
    /* @media only screen and (max-width: 1200px){
        .nlf-inner{
            width: 50%;
        }
    } */
    /* @media only screen and (max-width: 1145px){
        .navbar{
            font-size: 75%;
        }
    } */
    @media only screen and (max-width: 1050px){
        html{
            display: inline;
        }
        .navbar{
            font-size: 75%;
        }
        .navbar-button span{
            padding: 0.5rem;
            padding-left: 1rem;
            padding-right: 1rem;
        }
        /* .stb-active{
            position: relative;
            width: 100%;
            top: 0;
            right: 0;
        } */
    }
    @media only screen and (max-width: 750px){
        .nav-pad{
            padding-top: 0.75rem;
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .navbar-logo{
            width: 50%;
            padding: 0;
        }
        .navbar-menu{
            position: fixed;
            top: 0;
            left: 110%;
            width: 75%;
            height: 150%;
            transition: 0.2s;
            text-align: left;
            padding-left: 1%;
            padding-right: 15%;
            font-size: 1rem;
            background: var(--white);
        }
        .nm-mobile-close{
            display: block;
            position: absolute;
            top: 1rem;
            right: 4.1%;
            width: 1.5rem;
            height: 1.5rem;
        }
        .nm-mobile-close img{
            width: 100%;
            max-width: 1.5rem;
            height: 100%;
        }
        .navbar-menu-bg{
            left: 0;
        }
        .navbar-login-form{
            padding-top: 0;
        }
        /* .nlf-inner{
            width: 100%;
            height: 100%;
            border-radius: 0;
        } */
        .nm-inner{
            display: inline;
        }
        .nm-mobile{
            display: block;
        }
        .nm-mobile-loggedin{
            display: block;
        }
        .nm-mobile img{
            width: 1.5rem;
        }
        .navbar-button{
            width: 50%;
            padding: 1rem;
            padding-top: 0.5rem;
            padding-right: 0;
        }
        .nm-desktop{
            display: none;
        }
        /* .nb-login{
            display: none;
        }
        .nb-login-loggedin{
            display: none;
        } */
        .nb-menumob{
            display: block;
        }
    }
    /* footer */
    footer{
        position: relative;
        background: var(--white);
    }
    .footer-top{
        display: flex;
        flex-wrap: wrap;
        padding-left: 10%;
        padding-right: 10%;
        background: #FEF8F0;
        height: 10.25rem;
        transition: 0.2s;
    }
    .staff{
        width: 60%;
        display: flex;
        padding-top: 3.87rem;
        transition: 0.2s;
    }
    .staff-icon{
        position: relative;
        min-width: 100px;
        width: 19%;
        max-width: 110px;
        min-height: 75px;
    }
    .staff-icon img{
        position: absolute;
        width: 55%;
    }
    .staff-icon-phone{
        top: 0;
        left: 0;
        z-index: 1;
    }
    .staff-icon-male{
        top: 0;
        right: 0;
        z-index: 0;
    }
    .staff-text{
        font-weight: 600;
        padding-left: 3.6rem;
        color: #1B4674;
        transition: 0.2s;
    }
    .staff-text p{
        margin: 0;
    }
    .sosign{
		width: 40%;
		display: flex;
		flex-wrap: wrap;
		justify-content: right;
	}
    .socials{
		width: 50%;
		display: flex;
		justify-content: right;
		padding-top: 3.87rem;
		transition: 0.2s;
	}
	.socials-icon{
		margin: 0.5rem;
		height: 1.9rem;
        border-radius: 50px;
        overflow: hidden;
	}
	.socials-icon img{
		height: 100%;
	}
	.signup{
		width: 50%;
		display: flex;
		justify-content: right;
		align-items: center;
	}
	.signup-button button{
		border: none;
		border-radius: 5rem;
		background: var(--navy);
		color: var(--white);
		padding: 0.5rem 1rem 0.5rem 1rem;
		cursor: pointer;
		user-select: none;
        transition: 0.2s;
	}
    .signup-button button:hover{
        background: var(--navy-hover);
    }
    .signup-modal{
		padding: 1rem 25% 5rem 25%;
		transition: 0.2s;
        overflow: auto;
	}
	.signup-modal-head{
		position: absolute;
		top: 0;
		right: 0;
		color: var(--white);
		padding: 1rem;
		z-index: 19;
	}
	.smh-close{
		cursor: pointer;
		/* z-index: 19; */
	}
	.signup-modal-body{
		position: relative;
		/* padding: 5rem; */
		/* display: flex; */
		/* justify-content: center; */
		animation-name: animatesignup;
		animation-duration: 0.5s;
		/* border: 1px solid green; */
		transition: 0.2s;
        text-align: left;
        /* overflow: auto; */
	}
	.smb-inner{
		background: var(--white);
		color: var(--grey);
		min-height: 200px;
		width: 100%;
		padding: 1rem;
		border-radius: 10px;
	}
	.smb-form-input{
		width: 100%;
		margin-bottom: 1rem;
	}
	.smb-form-input input{
		width: 100%;
        border: none;
        border-bottom: 1px solid #D3D3D3;
        outline: none;
        padding: 1% 0 1% 0;
        color: #575757;
	}
	.smb-form-input button{
		background: var(--navy);
		color: var(--white);
		border: none;
        border-radius: 50px;
		padding: 0.5rem 1rem 0.5rem 1rem;
		transition: 0.2s;
		cursor: pointer;
		user-select: none;
	}
	.smb-form-input button:hover{
		background: var(--navy-hover);
	}
	.sfi-label{
		font-size: 1rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
	}
	.sfi-select{
        position: relative;
        color: var(--grey);
        font-size: 0.9rem;
        user-select: none;
    }
    .sfi-select-button{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        background: var(--smoke);
        padding: 1rem;
        cursor: pointer;
        user-select: none;
        overflow: hidden;
    }
    .btn-cover{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    .ssb-text{
        width: 90%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .float-icon{
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: right;
    }
    .float-icon img{
        transition: 0.2s;
    }
    .float-icon-active img{
        transform: rotate(180deg);
    }
    .sfi-select-option{
        position: absolute;
        top: 110%;
        left: 0;
        width: 100%;
        min-height: 50px;
		/* max-height: 200px; */
        display: none;
        background: var(--white);
        border: 2px solid var(--smoke);
        overflow: auto;
        z-index: 5;
    }
    .float-active{
        display: block;
    }
	.sso-notif{
		padding: 0.5rem 1rem 0.5rem 1rem;
		color: var(--navy);
		font-size: 0.75rem;
		font-style: italic;
	}
	.sso-button-list{
		max-height: 200px;
		overflow: auto;
	}
    .sso-button{
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem 0.5rem 1rem;
        transition: 0.2s;
        cursor: pointer;
        user-select: none;
    }
    .sso-button:hover{
        background: var(--smoke);
    }
	.sso-button-daytime{
		cursor: auto;
		height: auto;
	}
	.sso-button-daytime:hover{
		background: var(--white);
	}
	.sso-button-schedule{
		display: flex;
		width: 100%;
		border-bottom: 1px solid var(--softgrey);
	}
	.sbs-day{
		width: 30%;
	}
	.sbs-clock{
		width: 70%;
		text-align: center;
	}
	.sbs-clock-list{
		/* cursor: pointer; */
		/* background: var(--primary); */
		/* color: var(--white); */
		padding: 0.5rem;
	}
	.scl-inner{
		cursor: pointer;
		background: var(--navy);
		color: var(--white);
		padding: 0.5rem;
		transition: 0.2s;
	}
	.scl-inner:hover{
		background: var(--navy-hover);
	}
	.scl-inner-appointment{
		font-style: italic;
		font-size: 0.75rem;
	}
    .sso-note{
        margin: 1rem 0 1rem 0;
        text-align: center;
        font-size: 1rem;
        font-family: roboto;
        font-weight: 300;
        font-style: italic;
        color: var(--softgrey);
    }
    /* ===== */
    .footer-middle{
        display: flex;
        flex-wrap: wrap;
        /* height: 14.69rem; */
        width: 100%;
        padding: 0 10% 0 10%;
        transition: 0.2s;
        /* border: 1px solid green; */
        background: var(--white);
    }
    .fm-logo{
        width: 5%;
        padding-top: 5.94rem;
        transition: 0.2s;
    }
    .fm-text{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: 2.5rem 0 2.5rem 0;
        color: #1C4677;
        transition: 0.2s;
    }
    .fm-office{
        width: 50%;
        padding: 1rem 1rem 0 0;
        transition: 0.2s;
    }
    .fm-contact{
        width: 50%;
        transition: 0.2s;
    }
    .fm-text h2{
        font-size: 1.125rem;
        font-weight: 600;
        margin: 0 0 0.5rem 0;
    }
    .fm-text p{
        margin: 0;
        margin-top: 0.2rem;
        font-weight: 400;
        font-size: 1rem;
        transition: 0.2s;
    }
    .footer-bottom{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 8.0625rem;
        padding-left: 10%;
        padding-right: 10%;
        background-image: url('./assets/images/backgrounds/ftbt-background.webp');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        /* background-size: 100% 100%; */
        transition: 0.2s;
    }
    .footer-bottom p{
        font-size: 0.9rem;
        transition: 0.2s;
    }
    .fb-left{
        width: 50%;
        padding-top: 1.25rem;
        color: #1C4677;
        transition: 0.2s;
    }
    .fb-right{
        position: relative;
        display: flex;
        justify-content: center;
        width: 50%;
        transition: 0.2s;
    }
    .fbr-medals{
        margin-top: 2.0625rem;
    }
    .fbr-text{
        margin-top: 2.5rem;
        margin-left: 1.3125rem;
        transition: 0.2s;
        font-size: 0.9rem;
    }
    .fbr-text a{
        text-decoration: none;
        color: #1C4677;
    }
    .cookies-link{
        margin-left: 1rem;
    }
    .notification{
        display: none;
        position: fixed;
        top: 100px;
        right: 10%;
        background: rgb(76, 155, 230);
        color: var(--white);
        min-width: 200px;
        min-height: 50px;
        border-radius: 5px;
        padding: 0.5rem;
        box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15), 0 5px 20px 0 rgba(0, 0, 0, 0.15);
        user-select: none;
        animation-name: animatenotification;
        animation-duration: 0.5s;
        z-index: 100;
    }
    .notification-confirmation{
        display: none;
        position: fixed;
        top: 100px;
        right: 10%;
        background: var(--white);
        color: var(--grey);
        min-width: 200px;
        min-height: 50px;
        border-radius: 5px;
        padding: 0.5rem;
        box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15), 0 5px 20px 0 rgba(0, 0, 0, 0.15);
        user-select: none;
        animation-name: animatenotification;
        animation-duration: 0.5s;
        z-index: 100;
    }
    .notification-active{
        display: block;
    }
    .notification-message-text{
        text-align: center;
        padding: 0 0 1rem 0;
    }
    .nmt-true{
        color: var(--orange);
        padding: 1rem;
        border: 1px solid var(--orange);
        border-radius: 5px;
    }
    .nmt-false{
        color: var(--green);
        padding: 1rem;
        border: 1px solid var(--green);
        border-radius: 5px;
    }
    .nmt-error{
        color: var(--red);
        padding: 1rem;
        border: 1px solid var(--red);
        border-radius: 5px;
        user-select: auto;
        overflow: auto;
    }
    .notification-message-row{
        display: flex;
        width: 100%;
        padding: 0.5rem 0 0.5rem 0;
    }
    .nmr-param{
        width: 30%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .nmr-value{
        width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 0 0 0.5rem;
    }
    .notification-button{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem 0 1rem 0;
    }
    .nb-ok span{
        padding: 0.5rem 1rem 0.5rem 1rem;
        background: var(--navy);
        color: var(--white);
    }
    /* .chat-box{
        position: fixed;
        right: 10%;
        bottom: 5%;
        background: var(--white);
        box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15), 0 5px 20px 0 rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        z-index: 15;
        transition: 0.2s;
        display: none;
    }
    .chatbox-active{
        height: 450px;
    }
    .chat-box-button{
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        user-select: none;
        height: 75px;
        width: 290px;
    }
    .cbb-chaticon{
        width: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem;
    }
    .cbb-chaticon img{
        width: 100%;
    }
    .cbb-text{
        width: 75%;
        text-align: center;
    }
    .cbb-icon{
        width: 10%;
        text-align: center;
    }
    .chat-box-body{
        width: 290px;
        height: 390px;
        overflow: hidden;
        display: none;
    }
    .cbody-active{
        display: block;
    }
    .chat-box-stage{
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;
        border-top: 1px solid var(--softgrey);
        height: 100%;
    }
    .cbs-part{
        height: 100%;
    }
    .chat-box-select{
        min-width: 100%;
        height: 100%;
    }
    .chat-box-option{
        margin-bottom: 0.5rem;
        cursor: pointer;
        transition: 0.2s;
        padding: 0.5rem;
        user-select: none;
    }
    .chat-box-option:hover{
        background: var(--smoke);
    }
    .cbs-doctor{
        min-width: 100%;
        height: 100%;
        overflow: hidden;
    }
    .cbs-doctor-inner{
        padding: 0.5rem;
        height: 100%;
        overflow: auto;
        background: var(--primary);
        background-image: url('../src/assets/images/backgrounds/chatscreenbg.jpg');
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .cbs-doctor-head{
        height: 7.5%;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .cbs-doctor-body{
        height: 92.5%;
    }
    .cbs-back{
        cursor: pointer;
    }
    .cbs-back img{
        transform: rotate(90deg);
    }
    .cdb-inner{
        height: 100%;
    }
    .cdb-tab{
        height: 100%;
    }
    .cdb-tab-head{
        display: flex;
        height: 15%;
        overflow: auto;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        align-items: center;
    }
    .cdb-tab-head::-webkit-scrollbar{
        display: none;
    }
    .cth-inner{
        min-width: 100px;
        height: 37px;
        border-radius: 5px;
        padding: 0.15rem;
        padding-left: 1rem;
        padding-right: 1rem;
        display: flex;
        align-items: center;
        font-size: 0.9rem;
        margin-right: 0.5rem;
        cursor: pointer;
        user-select: none;
        background: var(--white);
        color: var(--grey);
        box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15), 0 5px 20px 0 rgba(0, 0, 0, 0.15);
    }
    .cth-inner-active{
        background: var(--primary);
        color: var(--white);
    }
    .cth-inner-text{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .cdb-tab-body{
        display: flex;
        height: 85%;
    }
    .ctb-inner{
        position: relative;
        display: none;
        padding-top: 0.5rem;
        height: 100%;
        min-width: 100%;
    }
    .ctb-inner-active{
        display: block;
    }
    .ctb-screen{
        height: 75%;
        padding: 0.5rem;
        overflow: auto;
        scroll-behavior: smooth;
    }
    .ctb-screen-board{
        display: flex;
    }
    .csboard-you{
        justify-content: right;
        text-align: left;
    }
    .csboard-they{
        justify-content: left;
        text-align: right;
    }
    .csboard-inner{
        background: var(--white);
        box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.15), 0 5px 5px 0 rgba(0, 0, 0, 0.15);
        border: 1px solid var(--smoke);
        border-radius: 5px;
        margin-top: 0.5rem;
        padding: 0.5rem;
        overflow-wrap: break-word;
        min-width: 37%;
        max-width: 90%;
    }
    .csb-sender{
        font-size: 0.75rem;
        font-style: italic;
    }
    .csb-sender-you{
        color: rgb(14, 147, 209);
    }
    .csb-sender-they{
        color: rgb(126, 199, 159);
    }
    .csb-message{
        font-size: 0.9rem;
        color: var(--grey);
    }
    .csb-report-you{
        text-align: right;
    }
    .csb-report-they{
        text-align: left;
    }
    .csb-sender-time{
        font-size: 0.67rem;
        color: var(--softgrey);
    }
    .csb-sender-check{
        font-size: 0.75rem;
        color: var(--grey);
        padding-left: 0.5rem;
    }
    .csc-clock img{
        max-width: 10px;
    }
    .csc-one img{
        max-width: 10px;
    }
    .ctb-field{
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        height: 15%;
        width: 100%;
        padding-top: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 1rem;
    }
    .ctb-field p{
        margin: 0;
        font-size: 0.9rem;
    }
    .ctb-field-input{
        width: 85%;
        padding-right: 0.5rem;
    }
    .cfi-inner{
        height: 100%;
        width: 100%;
        border: 1px solid var(--softgrey);
        border-radius: 100px;
        overflow: hidden;
        padding: 0.5rem;
        background: var(--white);
    }
    .ctb-field-input input{
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        padding: 1%;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .ctb-field-button{
        width: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--grey);
        color: var(--white);
        font-size: 0.9rem;
        cursor: pointer;
        user-select: none;
        padding: 0.5rem;
    }
    .ctb-field-unpaid{
        justify-content: center;
        height: auto;
        padding: 0.5rem;
        background: var(--white);
        border: 1px solid var(--softgrey);
        border-radius: 5px;
    }
    .ctb-field-unpaid p{
        font-style: italic;
        color: red;
        text-align: center;
    }
    .ctb-field-paid{
        justify-content: center;
        height: auto;
        padding: 0.5rem;
        background: var(--white);
        border: 1px solid var(--softgrey);
        border-radius: 5px;
    }
    .ctb-field-paid p{
        font-style: italic;
        color: navy;
        text-align: center;
    }
    .ctb-field-done{
        justify-content: center;
        height: auto;
        padding: 0.5rem;
        background: var(--white);
        border: 1px solid var(--softgrey);
        border-radius: 5px;
    }
    .ctb-field-done p{
        font-style: italic;
        color: navy;
        text-align: center;
    }
    .cfd-inner{
        position: relative;
        height: 100%;
        overflow: hidden;
    }
    .rate-button{
        display: flex;
        justify-content: center;
        padding-top: 0.5rem;
    }
    .rate-button button{
        padding: 0.5rem 1rem 0.5rem 1rem;
        background: var(--primary);
        color: var(--white);
        border: none;
    }
    .rate-modal{
        position: absolute;
        left: 0;
        bottom: -200%;
        width: 100%;
        height: 100%;
        background: var(--white);
        transition: 0.5s;
    }
    .rate-modal-inner{
        position: relative;
    }
    .rate-modal-close{
        position: absolute;
        top: 0;
        right: 0;
        width: 10px;
        height: 10px;
        cursor: pointer;
    }
    .rate-modal-star{
        display: flex;
        width: 100%;
        justify-content: center;
        user-select: none;
    }
    .rms-inner{
        display: flex;
        width: 50%;
    }
    .rms-icon{
        position: relative;
        width: 20%;
        height: 50px;
        padding: 0.1rem;
        cursor: pointer;
    }
    .rms-icon img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
    .rms-icon-silver{
        display: block;
        z-index: 1;
    }
    .rms-icon-gold{
        z-index: 5;
    }
    .rate-modal-input{
        width: 100%;
    }
    .rate-modal-input textarea{
        width: 100%;
        height: 75px;
        outline: none;
        padding: 0.5rem;
        border: 1px solid var(--softgrey);
    }
    .ctb-field-rated{
        justify-content: center;
        height: auto;
        padding: 0.5rem;
        background: var(--white);
        color: var(--grey);
        border: 1px solid var(--softgrey);
        border-radius: 5px;
    } */
    .cbs-cs{
        min-width: 100%;
    }
    .cbs-cs-inner{
        padding: 0.5rem;
        height: 370px;
    }
    .cbs-cs-head{
        height: 15%;
        border-bottom: 1px solid var(--softgrey);
    }
    .cbs-cs-body{
        height: 85%;
        padding: 0.5rem;
    }
    .link-tree-icon{
        position: fixed;
        right: 50px;
        bottom: 2.5%;
        width: 75px;
        height: 75px;
        background: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 75px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
        z-index: 11;
        overflow: hidden;
        transition: 0.5s;
    }
    .link-tree-icon img{
        width: 100%;
        height: 100%;
        transition: 0.5s;
    }
    .go-top{
        display: flex;
        position: fixed;
        right: 1%;
        bottom: -15%;
        width: 75px;
        height: 75px;
        background: #a1a2a359;
        color: #515253;
        justify-content: center;
        align-items: center;
        z-index: 19;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.2s;
        user-select: none;
    }
    .go-top img{
        transform: rotate(180deg);
    }
    @media only screen and (max-width: 1650px){
        /* .fm-logo{
            width: 26%;
        } */
        /* .fm-text{
            width: 74%;
        } */
    }
    @media only screen and (max-width: 1500px){
        .footer-top{
            padding-left: 5%;
            padding-right: 5%;
        }
        .footer-middle{
            padding-left: 5%;
            padding-right: 5%;
        }
        .footer-bottom{
            padding-left: 5%;
            padding-right: 5%;
        }
        .notification{
            right: 5%;
        }
        .notification-confirmation{
            min-width: unset;
            right: 5%;
            left: 5%;
        }
        .chat-box{
            right: 7.5%;
            /* bottom: 15%; */
        }
        .go-top{
            width: 50px;
            height: 50px;
            right: 1%;
        }
    }
    @media only screen and (max-width: 1015px){
        .footer-top{
            height: auto;
        }
        .staff{
            width: 100%;
        }
        .sosign{
			width: 100%;
			padding: 0 0 1rem 0;
		}
		.socials{
			justify-content: left;
			align-items: center;
			padding: 0;
		}
        .chat-box{
            right: 10%;
        }
    }
    @media only screen and (max-width: 750px){
        .cbs-doctor-body{
            /* min-height: 666px; */
            /* border: 1px solid green; */
        }
        .staff{
            padding-top: 2.5rem;
        }
        .staff-text{
            padding-left: 5%;
        }
        .footer-top{
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .signup-modal{
			padding: 0;
		}
		.smh-close{
			/* position: absolute; */
			color: var(--navy);
			/* z-index: 100; */
		}
        .signup-modal-body{
			height: 100%;
		}
		.smb-inner{
			border-radius: 0;
			height: 100%;
			overflow: auto;
			padding: 2.5rem 1rem 1rem 1rem;
		}
        .footer-middle{
            height: auto;
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .fm-logo{
            width: 100%;
            padding-top: 2rem;
        }
        .fm-text{
            width: 100%;
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
        .fm-text p{
            font-size: 0.9rem;
        }
        .fm-office{
            width: 100%;
            padding-left: 0;
        }
        .fm-contact{
            width: 100%;
            margin-top: 2rem;
        }
        .footer-bottom{
            height: 10rem;
            width: 100%;
            padding-left: 2.5%;
            padding-right: 2.5%;
        }
        .footer-bottom p{
            font-size: 0.75rem;
        }
        .fb-left{
            width: 100%;
            padding-top: 2rem;
        }
        .fb-left p{
            margin: 0;
        }
        .fb-right{
            width: 100%;
            justify-content: left;
        }
        .fbr-text{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            margin-top: 0;
            margin-left: 0;
            font-size: 0.75rem;
        }
        .notification{
            right: 2.5%;
        }
        .notification-confirmation{
            right: 2.5%;
            left: 2.5%;
            min-width: 200px;
        }
        .chat-box{
            right: unset;
            left: 2.5%;
            z-index: 5;
        }
        .go-top{
            right: 2.5%;
            border-radius: 50px;
        }
    }
    @media only screen and (max-width: 450px){
        .chat-box{
            width: 75px;
            /* right: unset; */
            /* left: 2.5%; */
            bottom: 5%;
            border-radius: 5px;
            transition: unset;
            /* z-index: 5; */
            background: none;
            box-shadow: unset;
        }
        .chatbox-active{
            min-width: 200px;
            width: 100%;
            height: 100%;
            left: 0;
            bottom: 0;
            border-radius: 0;
            z-index: 17;
            background: var(--white);
        }
        .chat-box-head{
            /* height: 7.5%; */
            width: 100%;
            /* border: 1px solid green; */
        }
        .chat-box-button{
            /* height: auto; */
            width: 100%;
            /* background: var(--white); */
            /* align-items: flex-end; */
            align-items: baseline;
        }
        .cbb-chaticon{
            width: 90%;
            justify-content: right;
            align-items: baseline;
            /* padding: 0; */
            /* padding-top: 0; */
            /* border: 1px solid green; */
        }
        .cbb-chaticon img{
            max-height: 50px;
            width: auto;
        }
        .cbb-text{
            display: none;
            /* width: 70%; */
            /* border: 1px solid green; */
        }
        .cbb-icon{
            display: none;
            /* width: 10%; */
            /* border: 1px solid green; */
        }
        .chat-box-body{
            width: 100%;
            /* height: 85%; */
        }
        .cbody-active{
            height: 92.5%;
        }
        .chat-box-stage{
            height: 100%;
        }
        .cbs-doctor-inner{
            /* height: 100%; */
            /* border: 1px solid green; */
        }
        .cbs-doctor-head{
            /* border: 1px solid green; */
            max-height: 37px;
        }
        .cbs-doctor-body{
            /* border: 1px solid green; */
        }
        .cdb-tab-head{
            /* border: 1px solid green; */
            height: 10%;
            max-height: 50px;
            align-items: unset;
        }
        .cdb-tab-body{
            /* border: 1px solid green; */
            height: 90%;
        }
        .ctb-screen{
            height: 85%;
        }
        .ctb-field{
            /* border: 1px solid green; */
            /* min-height: 45px; */
            height: 15%;
            /* max-height: 50px; */
            align-items: center;
        }
        .ctb-field-unpaid{
            height: auto;
        }
        .ctb-field-paid{
            height: auto;
        }
        /* .ctb-field-done{
            height: 200px;
        } */
        .ctb-field-rated{
            height: auto;
        }
    }
    .element-hidden{
		display: none;
	}
    .navbar-down{
        z-index: 1;
    }
    /*  */
</style>